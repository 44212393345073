import fpProps from "../../../../src/posts/hierarchical-forecasting/frameworkPopularity";
import forecastData from "../../../../src/posts/hierarchical-forecasting/forecastData";
import forecastObliqueData from "../../../../src/posts/hierarchical-forecasting/forecastObliqueData";
import forecastMintData from "../../../../src/posts/hierarchical-forecasting/forecastMintData";
import forecastProjectionData from "../../../../src/posts/hierarchical-forecasting/forecastProjectionData";
import dataset3DScatterData from "../../../../src/posts/hierarchical-forecasting/dataset3DScatterData";
import datasetLinechart from "../../../../src/posts/hierarchical-forecasting/datasetLinechart";
import projection_tikz from "../../../../src/posts/hierarchical-forecasting/projection_tikz.png";
import * as React from 'react';
export default {
  fpProps,
  forecastData,
  forecastObliqueData,
  forecastMintData,
  forecastProjectionData,
  dataset3DScatterData,
  datasetLinechart,
  projection_tikz,
  React
};