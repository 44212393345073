import lassoPathsData from "../../../../src/posts/lasso-and-ridge/lassoPathsData";
import ridgePathsData from "../../../../src/posts/lasso-and-ridge/ridgePathsData";
import lassoSurfaceData from "../../../../src/posts/lasso-and-ridge/lassoSurfaceData";
import ridgeSurfaceData from "../../../../src/posts/lasso-and-ridge/ridgeSurfaceData";
import lasso2dImage from "../../../../src/posts/lasso-and-ridge/l1.png";
import ridge2dImage from "../../../../src/posts/lasso-and-ridge/l2.png";
import laplacianDist from "../../../../src/posts/lasso-and-ridge/laplace_norm_distribution.png";
import * as React from 'react';
export default {
  lassoPathsData,
  ridgePathsData,
  lassoSurfaceData,
  ridgeSurfaceData,
  lasso2dImage,
  ridge2dImage,
  laplacianDist,
  React
};