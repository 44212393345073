/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/roboto";
import "@fontsource/inconsolata"; 
import React from 'react';
import {AnimatePresence} from 'framer-motion';

require("prismjs/themes/prism-solarizedlight.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require('katex/dist/katex.min.css')


global.process = require("process")

const ReactDOM = require('react-dom');
export function replaceHydrateFunction() {
    return (element, container, callback) => {
        ReactDOM.render(element, container, callback)
    }
}


export const wrapPageElement = ({element}) => (
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  );