import {planeCoordinates} from "./forecastProjectionData"


export default {
    data :  [{
        "x": [48.8937121972223, 49.5366684843891, 50.269201563229, 49.2827454966386, 48.882798586592, 49.5763731497363, 48.914850773525, 47.4237209176151, 47.2271658796443, 47.4723462536336],
        "y": [6.05147243184265, 5.87131115471753, 6.82481367322935, 8.32283430236389, 8.50504850972895, 6.23002691331886, 7.59348729969213, 7.67679991301082, 7.76156222363281, 7.5873799355953],
        "z": [53.2808521426542, 54.1072910230886, 55.634345486937, 56.7175932979103, 56.3244565440706, 54.3484024145975, 55.9887126638349, 54.5805924737252, 54.0119617422601, 54.1371100115839],
        "mode": "markers",
        "marker": {
            "color": "red",
            "size": 5,
            "line": {
                "color": "rgba(31,119,180,1)"
            }
        },
        "type": "scatter3d",
        "error_y": {
            "color": "rgba(31,119,180,1)"
        },
        "error_x": {
            "color": "rgba(31,119,180,1)"
        },
        "line": {
            "color": "rgba(31,119,180,1)"
        },
        "frame": null
    }, {
        "colorbar": {
            "title": "europe<br />europe",
            "ticklen": 2
        },
        "colorscale": [
            [0, "#1c6e8cAA"],
            [1, "#1c6e8cAA"]
        ],
        "showscale": false,
        ...planeCoordinates,
        "mode": "markers",
        "marker": {
            "size": 5,
            "color": "red"
        },
        "type": "surface",
        "frame": null
    }, {
        "x": [48.6163234494871, 49.3198870483861, 50.0259232716421, 49.1347477464566, 48.7055668278836, 49.33337354166, 48.828246538628, 47.337066191465, 47.0643714861415, 47.3185768906928],
        "y": [4.94191744090221, 5.00418541070551, 5.85170050688182, 7.73084330163574, 7.79612147489543, 5.25802848101379, 7.24707036010401, 7.33018100841029, 7.11038464962145, 6.97230248383195],
        "z": [53.5582408903894, 54.3240724590916, 55.8776237785239, 56.8655910480923, 56.501688302779, 54.5914020226738, 56.0753168987319, 54.6672471998753, 54.1747561357629, 54.2908793745247],
        "mode": "markers",
        "marker": {
            "color": "green",
            "size": 5,
            "line": {
                "color": "rgba(44,160,44,1)"
            }
        },
        "type": "scatter3d",
        "error_y": {
            "color": "rgba(44,160,44,1)"
        },
        "error_x": {
            "color": "rgba(44,160,44,1)"
        },
        "line": {
            "color": "rgba(44,160,44,1)"
        },
        "frame": null
    }],
    "layout" : {
        "scene": {
            "camera": {
                "eye": {
                    "x": 2,
                    "y": 0.02,
                    "z": 0.8
                }
            },
            "aspectmode": "data",
            "xaxis": {
                "title": "france"
            },
            "yaxis": {
                "title": "italy"
            },
            "zaxis": {
                "title": "europe"
            }
        },
        "hovermode": "closest",
        "showlegend": false
    },

    caption : "Figure 6. Another approach for projecting base forecasts onto the coherent subspace. The green dots are forecasts reconcilied after oblique projection of the red dots, our base forecasts."
}