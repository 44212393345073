export default {
    "data": [{
        "x": [48.8937121972223, 49.5366684843891, 50.269201563229, 49.2827454966386, 48.882798586592, 49.5763731497363, 48.914850773525, 47.4237209176151, 47.2271658796443, 47.4723462536336],
        "y": [6.05147243184265, 5.87131115471753, 6.82481367322935, 8.32283430236389, 8.50504850972895, 6.23002691331886, 7.59348729969213, 7.67679991301082, 7.76156222363281, 7.5873799355953],
        "z": [53.2808521426542, 54.1072910230886, 55.634345486937, 56.7175932979103, 56.3244565440706, 54.3484024145975, 55.9887126638349, 54.5805924737252, 54.0119617422601, 54.1371100115839],
        "mode": "markers",
        "marker": {
            "color": "red",
            "size": 5,
            "line": {
                "color": "rgba(31,119,180,1)"
            }
        },
        "type": "scatter3d",
        "error_y": {
            "color": "rgba(31,119,180,1)"
        },
        "error_x": {
            "color": "rgba(31,119,180,1)"
        },
        "line": {
            "color": "rgba(31,119,180,1)"
        },
        "frame": null
    }, {
        "colorbar": {
            "title": "europe",
            "ticklen": 2
        },
        "colorscale": [
            [0, "#1c6e8cAA"],
            [1, "#1c6e8cAA"]
        ],
        "showscale": false,
        "x": [46.7271658796443, 46.8271658796443, 46.9271658796443, 47.0271658796443, 47.1271658796443, 47.2271658796443, 47.3271658796443, 47.4271658796443, 47.5271658796443, 47.6271658796443, 47.7271658796443, 47.8271658796443, 47.9271658796443, 48.0271658796443, 48.1271658796443, 48.2271658796443, 48.3271658796443, 48.4271658796443, 48.5271658796443, 48.6271658796443, 48.7271658796443, 48.8271658796443, 48.9271658796443, 49.0271658796443, 49.1271658796443, 49.2271658796443, 49.3271658796443, 49.4271658796443, 49.5271658796443, 49.6271658796443, 49.7271658796443, 49.8271658796443, 49.9271658796443, 50.0271658796443, 50.1271658796443, 50.2271658796443, 50.3271658796443, 50.4271658796443, 50.5271658796443, 50.6271658796443, 50.7271658796443],
        "y": [3.37131115471753, 3.47131115471753, 3.57131115471753, 3.67131115471753, 3.77131115471753, 3.87131115471753, 3.97131115471753, 4.07131115471753, 4.17131115471753, 4.27131115471753, 4.37131115471753, 4.47131115471753, 4.57131115471753, 4.67131115471753, 4.77131115471753, 4.87131115471753, 4.97131115471753, 5.07131115471753, 5.17131115471753, 5.27131115471753, 5.37131115471753, 5.47131115471753, 5.57131115471753, 5.67131115471753, 5.77131115471753, 5.87131115471753, 5.97131115471753, 6.07131115471753, 6.17131115471753, 6.27131115471753, 6.37131115471753, 6.47131115471753, 6.57131115471753, 6.67131115471753, 6.77131115471753, 6.87131115471753, 6.97131115471753, 7.07131115471753, 7.17131115471753, 7.27131115471753, 7.37131115471753, 7.47131115471753, 7.57131115471753, 7.67131115471753, 7.77131115471753, 7.87131115471753, 7.97131115471753, 8.07131115471753, 8.17131115471753, 8.27131115471753, 8.37131115471753, 8.47131115471753, 8.57131115471753, 8.67131115471753, 8.77131115471753, 8.87131115471753, 8.97131115471753],
        "z": [
            [50.0984770343619, 50.1984770343619, 50.2984770343619, 50.3984770343619, 50.4984770343619, 50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619],
            [50.1984770343619, 50.2984770343619, 50.3984770343619, 50.4984770343619, 50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619],
            [50.2984770343619, 50.3984770343619, 50.4984770343619, 50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619],
            [50.3984770343619, 50.4984770343619, 50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619],
            [50.4984770343619, 50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619],
            [50.5984770343619, 50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619],
            [50.6984770343619, 50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619],
            [50.7984770343619, 50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619],
            [50.8984770343619, 50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619],
            [50.9984770343619, 51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619],
            [51.0984770343619, 51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619],
            [51.1984770343619, 51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619],
            [51.2984770343619, 51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619],
            [51.3984770343619, 51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619],
            [51.4984770343619, 51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619],
            [51.5984770343619, 51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619],
            [51.6984770343619, 51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619],
            [51.7984770343619, 51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619],
            [51.8984770343619, 51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619],
            [51.9984770343619, 52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619],
            [52.0984770343619, 52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619],
            [52.1984770343619, 52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619],
            [52.2984770343619, 52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619],
            [52.3984770343619, 52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619],
            [52.4984770343619, 52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619],
            [52.5984770343619, 52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619],
            [52.6984770343619, 52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619],
            [52.7984770343619, 52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619],
            [52.8984770343619, 52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619],
            [52.9984770343619, 53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619],
            [53.0984770343619, 53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619],
            [53.1984770343619, 53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619],
            [53.2984770343619, 53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619],
            [53.3984770343619, 53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619],
            [53.4984770343619, 53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619],
            [53.5984770343619, 53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619],
            [53.6984770343619, 53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619],
            [53.7984770343619, 53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619],
            [53.8984770343619, 53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619],
            [53.9984770343619, 54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619],
            [54.0984770343619, 54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619],
            [54.1984770343619, 54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619],
            [54.2984770343619, 54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619],
            [54.3984770343619, 54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619],
            [54.4984770343619, 54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619],
            [54.5984770343619, 54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619],
            [54.6984770343619, 54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619],
            [54.7984770343619, 54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619],
            [54.8984770343619, 54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619],
            [54.9984770343619, 55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619],
            [55.0984770343619, 55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619],
            [55.1984770343619, 55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619],
            [55.2984770343619, 55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619, 59.2984770343619],
            [55.3984770343619, 55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619, 59.2984770343619, 59.3984770343619],
            [55.4984770343619, 55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619, 59.2984770343619, 59.3984770343619, 59.4984770343619],
            [55.5984770343619, 55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619, 59.2984770343619, 59.3984770343619, 59.4984770343619, 59.5984770343619],
            [55.6984770343619, 55.7984770343619, 55.8984770343619, 55.9984770343619, 56.0984770343619, 56.1984770343619, 56.2984770343619, 56.3984770343619, 56.4984770343619, 56.5984770343619, 56.6984770343619, 56.7984770343619, 56.8984770343619, 56.9984770343619, 57.0984770343619, 57.1984770343619, 57.2984770343619, 57.3984770343619, 57.4984770343619, 57.5984770343619, 57.6984770343619, 57.7984770343619, 57.8984770343619, 57.9984770343619, 58.0984770343619, 58.1984770343619, 58.2984770343619, 58.3984770343619, 58.4984770343619, 58.5984770343619, 58.6984770343619, 58.7984770343619, 58.8984770343619, 58.9984770343619, 59.0984770343619, 59.1984770343619, 59.2984770343619, 59.3984770343619, 59.4984770343619, 59.5984770343619, 59.6984770343619]
        ],
        "mode": "markers",
        "marker": {
            "size": 5,
            "color": "red"
        },
        "type": "surface",
        "frame": null
    }],
    "layout" : {
        "scene": {
            "camera": {
                "eye": {
                    "x": 2,
                    "y": 0.02,
                    "z": 0.8
                }
            },
            "aspectmode": "data",
            "xaxis": {
                "title": "france"
            },
            "yaxis": {
                "title": "italy"
            },
            "zaxis": {
                "title": "europe"
            }
        },
        "hovermode": "closest",
        "showlegend": false
    },
    caption: "Figure 3. Visualizing 14-days-ahead forecasts of ARIMA. The blue plane represents the coherent subspace, where observations should lie to respect the linear constraint defined by the problem."
}