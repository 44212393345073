import {planeCoordinates} from "./forecastProjectionData"

export default {
    data : [{
        "x": [48.0319995343696, 48.8632334225834, 49.5134535879802, 48.8229880751611, 48.3322252414738, 48.8214909093524, 48.645813307237, 47.154526599427, 46.7214424446881, 46.9946592231148],
        "y": [5.30374612980903, 5.2869573090206, 6.16903517169105, 7.92389306427663, 8.02730437402058, 5.57499962823231, 7.36003780028468, 7.44321430989233, 7.32273530350867, 7.17288079654722],
        "z": [53.3357456641785, 54.150190731604, 55.6824887596712, 56.7468811394377, 56.3595296154943, 54.3964905375846, 56.0058511075216, 54.5977409093193, 54.0441777481966, 54.1675400196619],
        "mode": "markers",
        "marker": {
            "color": "#ffd4ca",
            "size": 5,
            "line": {
                "color": "rgba(31,119,180,1)"
            }
        },
        "type": "scatter3d",
        "error_y": {
            "color": "rgba(31,119,180,1)"
        },
        "error_x": {
            "color": "rgba(31,119,180,1)"
        },
        "line": {
            "color": "rgba(31,119,180,1)"
        },
        "frame": null
    }, {
        "x": [48.8937121972223, 49.5366684843891, 50.269201563229, 49.2827454966386, 48.882798586592, 49.5763731497363, 48.914850773525, 47.4237209176151, 47.2271658796443, 47.4723462536336],
        "y": [6.05147243184265, 5.87131115471753, 6.82481367322935, 8.32283430236389, 8.50504850972895, 6.23002691331886, 7.59348729969213, 7.67679991301082, 7.76156222363281, 7.5873799355953],
        "z": [53.2808521426542, 54.1072910230886, 55.634345486937, 56.7175932979103, 56.3244565440706, 54.3484024145975, 55.9887126638349, 54.5805924737252, 54.0119617422601, 54.1371100115839],
        "mode": "markers",
        "marker": {
            "color": "red",
            "size": 5,
            "line": {
                "color": "rgba(255,127,14,1)"
            }
        },
        "type": "scatter3d",
        "error_y": {
            "color": "rgba(255,127,14,1)"
        },
        "error_x": {
            "color": "rgba(255,127,14,1)"
        },
        "line": {
            "color": "rgba(255,127,14,1)"
        },
        "frame": null
    }, {
        "colorbar": {
            "title": "europe<br />europe",
            "ticklen": 2
        },
        "colorscale": [
            [0, "#1c6e8cAA"],
            [1, "#1c6e8cAA"]
        ],
        "showscale": false,
        ...planeCoordinates,
        "mode": "markers",
        "marker": {
            "size": 5,
            "color": "#ffd4ca"
        },
        "type": "surface",
        "frame": null
    }],

    layout : {
        "scene": {
            "camera": {
                "eye": {
                    "x": 2,
                    "y": 0.02,
                    "z": 0.8
                }
            },
            "aspectmode": "data",
            "xaxis": {
                "title": "france"
            },
            "yaxis": {
                "title": "italy"
            },
            "zaxis": {
                "title": "europe"
            }
        },
        "hovermode": "closest",
        "showlegend": false
    },

    caption : "Figure 7. Visualizing MinT(sample) reconciliation. The rosé points are the reconcilied forecasts."
}